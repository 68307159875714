import './src/styles/global.css'
import "animate.css/animate.min.css";
// import './src/components/TransitionLinks/animations.css'

export const onRouteUpdate = () => {
  delete window.nvtag;
  const script = document.createElement("script");
  script.src = "https://static.everyaction.com/ea-actiontag/at.js";
  script.async = true;
  script.crossorigin = "anonymous"
  document.body.appendChild(script);
}