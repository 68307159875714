exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-kim-js": () => import("./../../../src/pages/about-kim.js" /* webpackChunkName: "component---src-pages-about-kim-js" */),
  "component---src-pages-endorsements-js": () => import("./../../../src/pages/endorsements.js" /* webpackChunkName: "component---src-pages-endorsements-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-how-we-win-js": () => import("./../../../src/pages/how-we-win.js" /* webpackChunkName: "component---src-pages-how-we-win-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-press-kim-nguyen-penaloza-announces-campaign-js": () => import("./../../../src/pages/press/kim-nguyen-penaloza-announces-campaign.js" /* webpackChunkName: "component---src-pages-press-kim-nguyen-penaloza-announces-campaign-js" */),
  "component---src-pages-press-kim-nguyen-penaloza-democratic-party-endorsement-js": () => import("./../../../src/pages/press/kim-nguyen-penaloza-democratic-party-endorsement.js" /* webpackChunkName: "component---src-pages-press-kim-nguyen-penaloza-democratic-party-endorsement-js" */),
  "component---src-pages-press-vi-js": () => import("./../../../src/pages/press-vi.js" /* webpackChunkName: "component---src-pages-press-vi-js" */),
  "component---src-pages-vi-404-js": () => import("./../../../src/pages/vi/404.js" /* webpackChunkName: "component---src-pages-vi-404-js" */),
  "component---src-pages-vi-about-kim-js": () => import("./../../../src/pages/vi/about-kim.js" /* webpackChunkName: "component---src-pages-vi-about-kim-js" */),
  "component---src-pages-vi-endorsements-js": () => import("./../../../src/pages/vi/endorsements.js" /* webpackChunkName: "component---src-pages-vi-endorsements-js" */),
  "component---src-pages-vi-gallery-js": () => import("./../../../src/pages/vi/gallery.js" /* webpackChunkName: "component---src-pages-vi-gallery-js" */),
  "component---src-pages-vi-how-we-win-js": () => import("./../../../src/pages/vi/how-we-win.js" /* webpackChunkName: "component---src-pages-vi-how-we-win-js" */),
  "component---src-pages-vi-index-js": () => import("./../../../src/pages/vi/index.js" /* webpackChunkName: "component---src-pages-vi-index-js" */),
  "component---src-pages-vi-press-js": () => import("./../../../src/pages/vi/press.js" /* webpackChunkName: "component---src-pages-vi-press-js" */),
  "component---src-pages-vi-press-kim-nguyen-penaloza-announces-campaign-js": () => import("./../../../src/pages/vi/press/kim-nguyen-penaloza-announces-campaign.js" /* webpackChunkName: "component---src-pages-vi-press-kim-nguyen-penaloza-announces-campaign-js" */),
  "component---src-pages-vi-press-kim-nguyen-penaloza-democratic-party-endorsement-js": () => import("./../../../src/pages/vi/press/kim-nguyen-penaloza-democratic-party-endorsement.js" /* webpackChunkName: "component---src-pages-vi-press-kim-nguyen-penaloza-democratic-party-endorsement-js" */),
  "component---src-pages-vi-volunteer-js": () => import("./../../../src/pages/vi/volunteer.js" /* webpackChunkName: "component---src-pages-vi-volunteer-js" */),
  "component---src-pages-volunteer-js": () => import("./../../../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */)
}

